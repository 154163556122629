import { ApolloError } from "@apollo/client";

export interface InvestBanks {
  investBank: {
    id: string;
    projects: {
      data: Project[];
      meta: {
        page: number;
        pageSize: number;
      }
    };
  };
}

export interface ProjectAsset {
  assetId: string;
  assetSymbol: string;
  displaySymbol: string;
  icon: string;
  title: string;
}

export interface ProjectPair {
  address: string;
  assetIdToken0: string;
  assetIdToken1: string;
  balance0: string;
  balance1: string;
  id: number;
  isActive: boolean;
}

export enum ProjectStatus {
  INITIAL_OFFERING = 'INITIAL_OFFERING',
  SECONDARY_MARKET = 'SECONDARY_MARKET',
  EXITED = 'EXITED',
  INITIAL_SALE_ENDED = 'INITIAL_SALE_ENDED'
}

export interface Project {
  id: string;
  title: string;
  description: string;
  status: ProjectStatus;
  shortDescription: string;
  link: string;
  country: string;
  currentCountry: string;
  restrictedCountries?: string[];
  logoImage: string;
  coverImage: string;
  startDate: string;
  endDate: string;
  softCap: string;
  hardCap: string;
  tokensTotal: string;
  tokensSold: string;
  priceInUSD: string;
  category: string;
  valuationInUSD: string;
  dailyInvestmentInUSD: string;
  projectAsset: ProjectAsset;
  projectPair?: ProjectPair;
  volume24h: string;
}

export interface AutoClickOptions {
  shouldClickNext?: boolean;
  shouldDisconnectRef?: boolean;
}

export interface CompaniesComponentProps {
  projects: Project[];
  loading: boolean;
  error?: ApolloError;
  hasMore: boolean;
  handleFetchMore: (page: number) => Promise<void>;
}
