import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import "./index.css";
import { ASSETS_URL } from "../../../utils";
import Button from "react-bootstrap/Button";

const ModalAlert = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem("isModalShown", "false");
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img style={{ paddingRight: "20px" }} src={`${ASSETS_URL}/warning.svg`} alt="warning" />Legal Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sorry, our offerings are not available for the residents of United States, Japan, Singapore, and Switzerland.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" className="modal__understand" onClick={handleClose}>I understand</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAlert;
