import React, { useState, FC } from "react";
import { Info } from "./Info";
import { Benefits } from "./Benefits";
import { Companies } from "./Companies";
import ModalAlert from "./Modal";
import "./index.css";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "./Companies/queries";
import { getModalVisibility, isCountryRestricted } from "./utils";

const initialPageSize = 12;

const Home: FC = () => {
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [projects, setProjects] = useState<any[]>([]);

  const { loading, error, fetchMore } = useQuery(GET_PROJECTS, {
    variables: {
      pageSize: initialPageSize,
      page: 1,
    },
    onCompleted: (data) => {
      setProjects(data.projects.data);
    },
    notifyOnNetworkStatusChange: true,
    skip: page !== 1,
    fetchPolicy: "network-only",
  });

  const isModalShown = getModalVisibility();
  const isRestricted = isCountryRestricted(projects);
  const isFullpage = projects.length >= initialPageSize;

  const handleFetchMoreProjects = async () => {
    const { data } = await fetchMore({
      variables: {
        page: page + 1,
        pageSize: initialPageSize,
      },
    });

    setProjects((prev) => [...prev, ...(data?.projects?.data || [])]);
    setPage((prev) => prev + 1);

    const projectsLength = projects.length;

    if (projectsLength === data.projects.meta.total) {
      sessionStorage.setItem("projectsLoaded", "true");
      setHasMore(false);
    }
  };

  return (
    <>
      <Info />

      <Benefits />

      <Companies
        error={error}
        hasMore={hasMore && isFullpage}
        loading={loading}
        projects={projects}
        handleFetchMore={handleFetchMoreProjects}
      />

      {isRestricted && isModalShown && <ModalAlert />}
    </>
  );
};

export default Home;
