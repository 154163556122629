import React from "react";
import { ASSETS_URL } from "../../../../utils";
import Link from "../../../Link";

export const Description = () => <>
  <p className="home__description">
    Unicorn Tokenization Corp is a licensed fund, that facilitates
    financial inclusion
    by making
    equity of Pre-IPO companies (aka Unicorns) available to smaller investors. We use innovative blockchain technology
    to make it possible.
  </p>

  <div className="home__link">
    <Link to="/about">Read more <img src={`${ASSETS_URL}/arrow_right.svg`} alt="Arrow right"/></Link>
  </div>
</>;
