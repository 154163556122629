import React from "react";
import { ASSETS_URL } from "../../utils";

export interface MediaIconI {
  id?: string;
  icon: string;
  url: string;
  newTab?: boolean;
}

const MediaIcon = ({ id, icon, url, newTab }: MediaIconI) => (
  <a
    id={id}
    href={url}
    target={newTab ? "_blank" : "_self"}
    rel="noreferrer"
    style={{ opacity: '0.5' }}
  >
    <img style={{ padding: "0 10px" }} src={`${ASSETS_URL}/${icon}`} alt="Icon"/>
  </a>
);

export default MediaIcon;
