import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import { ASSETS_URL } from "../../../utils";
import Link from "../../Link";
import { docsData } from "./data";

const Documentation = () => <div className="for-platforms__documentation">
  <Row>
    <Col>
      <h1 className="heading">Documentation</h1>
    </Col>
  </Row>

  <Row>
    {docsData.map(({ id, text, link }) => <Col xs={12} lg={4}>
      <Link id={id} to={link} external={true} newTab={true}>
        <div className="documentation__document">
          <Row>
            <Col xs={"auto"}>
              <img src={`${ASSETS_URL}/document_icon.svg`} alt="document"/>
            </Col>

            <Col className="document__label" style={{ marginLeft: "-9px" }}>{text}</Col>
          </Row>
        </div>
      </Link>
    </Col>)}
  </Row>
</div>;

export default Documentation;
