import React from "react";
import BigNumber from 'bignumber.js';
import { v5 } from "uuid";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { RowDivider } from "./RowDivider";
import Link from "../../../../Link";
import { ASSETS_URL } from "../../../../../utils";
import { TableRowI } from "./types";
import {
  getCompanyName,
  getLastPrice,
  getLatestValuation,
  getProjectLink,
  getTokenMarketCap,
  getVolume
} from "./utils";
// import DailyChangeData from "./DailyChangeData";
import useBreakpoints from "./useBreakpoints";
import "./index.css";
import { ProjectStatus } from '../../types';

const TableRow = (
  {
    project: {
      id,
      title,
      projectAsset,
      valuationInUSD,
      link,
      projectPair,
      priceInUSD,
      tokensTotal,
      dailyInvestmentInUSD,
      status,
      volume24h
    },
    pairDayDatas,
    history
  }: TableRowI & RouteComponentProps
) => {
  const isBreakpointsActive = useBreakpoints(["xs", "sm", "md"]);

  const projectLink = getProjectLink(projectAsset);
  const companyName = getCompanyName(title);
  const lastPrice = getLastPrice(projectPair, projectAsset.assetId, priceInUSD);
  const latestValuation = getLatestValuation(valuationInUSD);
  const tokenMarketCap = getTokenMarketCap(tokensTotal, lastPrice);
  const primaryVolume = getVolume(pairDayDatas, dailyInvestmentInUSD);
  const tradingVolume24 = status === ProjectStatus.SECONDARY_MARKET ? `$${new BigNumber(volume24h).toFixed(2)}` : primaryVolume;

  const onClickHandler = () => isBreakpointsActive
    ? history.push(projectLink)
    : undefined;

  return <>
    <RowDivider/>

    <tr className="table-row" onClick={onClickHandler}>
      {/*Company*/}
      <td>
        <Link to={projectLink} className="custom-link">
          <div id={id} className="d-flex">
            <img style={{ marginRight: "9px" }} width="24px" height="24px" src={projectAsset.icon} alt={companyName}/>
            <span className="d-flex flex-column justify-content-center text-nowrap font-weight-bold">
              {companyName}
            </span>
          </div>
        </Link>
      </td>

      {/*Symbol*/}
      <td className="font-weight-bold">
        <Link to={projectLink} className="custom-link">
          <span id={v5(projectAsset.assetId, id)}>{projectAsset.displaySymbol}</span>
        </Link>
      </td>

      {/*Last price*/}
      <td className="font-weight-bold">${lastPrice.toFormat(1)}</td>

      {/*24h*/}
      {/* <td>
        <DailyChangeData pairDayData={pairDayDatas} projectAssetDisplaySymbol={projectAsset.displaySymbol}/>
      </td> */}

      {/*Latest valuation*/}
      <td className="d-none d-md-table-cell">${latestValuation}B</td>

      {/*Token market cap*/}
      <td className=" d-none d-lg-table-cell">${tokenMarketCap}</td>

      {/*Volume*/}
      <td>{tradingVolume24}</td>

      <td className="d-none d-lg-block">
        <Link to={projectLink}>
          <span id={v5(link, id)} className="text-nowrap">
            View project <img src={`${ASSETS_URL}/arrow_right.svg`} alt="Arrow right"/>
          </span>
        </Link>
      </td>
    </tr>
  </>;
}

export default withRouter(TableRow);
