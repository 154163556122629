import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Project } from "../types";
import "./index.css";

interface TitleI {
  project: Project;
}

const Title = (
  {
    project: {
      title,
      logoImage,
      projectAsset: {
        icon
      },
      investmentProductDescription
    }
  }: TitleI
) => (
  <div className="title">
    <Row>
      <Col>
        <h1 className="heading">{title}</h1>
      </Col>
    </Row>

    <Row>
      <Col className="d-flex flex-column justify-content-center asset-icon" xs="auto">
        <img src={icon} className="asset-icon__icon" alt="Project logo" />
      </Col>

      <Col className="d-flex flex-column justify-content-center description">
        <span className="description__text">{investmentProductDescription}</span>
      </Col>

      <Col xs={12} md={4} className="d-flex flex-column justify-content-center align-items-center company-logo">
        <img src={logoImage} className="logo" alt="Cover" />
      </Col>
    </Row>
  </div>
);

export default Title;
