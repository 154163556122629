const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export const getBreakpoint = (windowWidth: number): string => {
  const { sm, lg, md, xl } = breakpoints;

  if (windowWidth < sm) {
    return "xs";
  } else if (windowWidth >= sm && windowWidth < md) {
    return "sm"
  } else if (windowWidth >= md && windowWidth < lg) {
    return "md";
  } else if (windowWidth >= lg && windowWidth < xl) {
    return "lg";
  } else if (windowWidth >= xl) {
    return "xl";
  } else {
    return "sm";
  }
};
