import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const RowDivider = ({ className }: { className?: string }) => (
  <Row className={className}>
    <Col xs={12}>
      <div className="info__row-divider"/>
    </Col>
  </Row>
);

export default RowDivider;
