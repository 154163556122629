import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import { ASSETS_URL } from "../../../utils";
import { PlatformData, PlatformsI } from "./types";
import { getPlatformsData } from "./utils";
import { AMBIVAULT_URLS_MAP } from "./data";

const Platforms = ({ projectAssetAddress, projectLink, providerId }: PlatformsI) => {
  const [platforms, setPlatforms] = useState<PlatformData[]>([]);

  useEffect(() => {
    (async () => {
      try {
        let mode = process?.env?.REACT_APP_MODE ? process?.env?.REACT_APP_MODE : "production";

        // Cause no Ambivault instance for staging. Only develop and production.
        if (mode === "staging") {
          mode = "develop";
        }

        const url = AMBIVAULT_URLS_MAP[mode][providerId];

        const response = await fetch(`${url}/account/token_info/${projectAssetAddress}/`);
        const jsonData = await response.json();

        setPlatforms(getPlatformsData(jsonData, projectLink));
      } catch (e) {
        console.log('Fetch Error :-S', e);
      }
    })();
  }, [projectAssetAddress, projectLink, providerId]);

  if (platforms.length === 0) {
    return null;
  }

  return (
    <div className="platforms">
      <Row>
        <Col>
          <h1 className="heading">Invest and Trade</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="platforms__description">The offering is available for trading at the following platforms:</p>
        </Col>
      </Row>

      <Row>
        {platforms.map(({ url, name, logo, logoHeight }, index) => <Col key={index} xs={12} md={4}>
          <a href={url} target="_blank" rel="noreferrer">
            <div className={`platforms__image ${name} d-flex justify-content-center align-items-center`}>
              <img src={`${ASSETS_URL}/${logo}`} alt="Platform" style={{ height: logoHeight, width: "auto" }}/>
            </div>
          </a>
        </Col>)}
      </Row>
    </div>
  )
};

export default Platforms;
