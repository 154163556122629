import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import { ASSETS_URL } from "../../../utils";
import Link from "../../Link";

const Partner = () => {
  const email = <Link to="mailto:partner@utc.fund" external={true}>partner@utc.fund</Link>;

  const text = <>
    <p className="partners__offering">We’re offering various reseller agreements, early access and allocations in our
      upcoming offerings to digital broker-dealers, cryptocurrency wallets, and exchange operators, who are ready to
      meet certain compliance requirements.
    </p>
    <p className="partners__contacts-us">Please contact us at {email} for more information.</p>
  </>;

  return (
    <div className="for-platforms__partners">
      <Row>
        <Col>
          <h1 id="99cba159-e102-4e8a-a2c3-54980b48e7d1" className="heading">Partner With Us!</h1>
        </Col>
      </Row>

      <Row>
        <Col xs="auto" className="partners__logo-container">
          <img className="partners__logo" src={`${ASSETS_URL}/for_platforms.svg`} alt="for platforms"/>
        </Col>

        <Col>
          <Row>
            <Col md={12}>
              <h3 className="partners__question">Interested in selling our tokens through your platform?</h3>
            </Col>

            <Col md={12} className="d-none d-md-flex flex-column">
              {text}
            </Col>
          </Row>
        </Col>

        <Col xs={12} className="d-md-none">
          {text}
        </Col>
      </Row>
    </div>
  );
};

export default Partner;
