import { AmbivaultURLsMap, PlatformsMapI } from "./types";

export const platformsMap: PlatformsMapI = {
  orderbook: {
    name: "orderbook",
    logo: "orderbook_logo.svg",
    logoHeight: "52px",
    url: "https://orderbook.io/projects",
  },
  raison: {
    name: "raison",
    logo: "raison_logo_2.svg",
    logoHeight: "45px",
    url: "https://raison.ai/assets",
  },
  roobee: {
    name: "roobee",
    logo: "roobee_logo.svg",
    logoHeight: "38px",
    url: "https://roobee.io/",
  },
};

export const AMBIVAULT_URLS_MAP: AmbivaultURLsMap = {
  develop: {
    default: "https://dev.eth.tabla.is",
    celo: "https://dev.celo.tabla.is",
  },
  production: {
    default: "https://eth.tabla.is",
    celo: "https://celo.tabla.is",
  },
};
