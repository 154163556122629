import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

export const ASSETS_URL = "https://assets.ambisafe.io";

const envMap: { [index: string]: string } = {
  develop: "https://api.develop.orderbook.io/graphql",
  staging: "https://api.staging.orderbook.io/graphql",
  production: "https://api.sto.orderbook.io/graphql",
  localhost: "http://localhost:5000/graphql",
};

export const getApolloClient = () => {
  const link = createHttpLink({
    fetch: (uri: string, options: any) => {
      const { operationName } = JSON.parse(options.body);
      const mode = process?.env?.REACT_APP_MODE
        ? process?.env?.REACT_APP_MODE
        : "production";

      return ["InvestBank", "Project", "Projects"].includes(operationName)
        ? fetch(envMap[mode], options)
        : fetch(
            "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2",
            options
          );
    },
  });

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        InvestBank: {
          keyFields: ["id"],
          merge: false,
        },
        Project: {
          keyFields: ["id"],
          merge: true,
        },
        ProjectAsset: {
          keyFields: ["assetId"],
          merge: true,
        },
      },
    }),
  });
};
