import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Header } from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Project from "./components/Project";
import Container from "react-bootstrap/Container";
import About from "./components/About";
import ForPlatforms from "./components/ForPlatforms";

const App = () => {
  return (
    <Router>
      <div style={{ backgroundColor: "#000", position: "relative", overflow: "hidden" }}>
        <Header/>

        <Container style={{ maxWidth: "1300px" }}>
          <Switch>
            <Route path="/" exact={true}>
              <Home/>
            </Route>

            <Route path="/about" exact={true}>
              <div className="about__background"/>
              <About/>
            </Route>

            <Route path="/for-platforms" exact={true}>
              <div className="for-platforms__background"/>
              <ForPlatforms/>
            </Route>

            <Route path="/:assetSymbol" render={({ match }) => {
              const assetSymbol: string = match.params.assetSymbol;

              if (/[a-z]/.test(assetSymbol)) {
                return <Redirect to={assetSymbol.toUpperCase()}/>;
              }

              return <Project assetSymbol={assetSymbol.toUpperCase()}/>;
            }}/>
          </Switch>
        </Container>

        <Footer/>
      </div>
    </Router>
  );
}

export default App;
