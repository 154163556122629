import React from "react";
import { useQuery } from "@apollo/client";
import { PAIR_DAY_DATAS } from "./queries";
import TableRow from "./TableRow";
import { PairDayDatasArgs, PairDayDatasResponse, TableRowContainerI } from "./types";

/*
Very bad peace of code. Need to rewrite this.
 */
export const TableRowContainer = ({ project }: TableRowContainerI) => {
  const { data, loading } = useQuery<PairDayDatasResponse, PairDayDatasArgs>(PAIR_DAY_DATAS, {
    variables: {
      pairAddress: project && project.projectPair ? project.projectPair.address : "",
    }
  });

  if (loading) {
    return <>Loading</>;
  }

  // if (error) {
  //   return <>Some error occurred</>;
  // }

  return <TableRow pairDayDatas={data && data.pairDayDatas ? data.pairDayDatas : []} project={project} />;
};
