import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import { Project } from "../types";
import BigNumber from "bignumber.js";
import { formatDate } from "./utils";
import RowDivider from "../CompanyDetails/RowDivider";
import { ShowIfTrue } from "../utils";

interface OfferingDetailsI {
  project: Project;
}

const OfferingDetails = (
  {
    project: {
      investmentProductDescription,
      priceInUSD,
      startDate,
      endDate,
      lockupEndDate,
      totalSharesAvailable,
      minInvestmentInUSD,
      projectPair,
      projectAsset
    }
  }: OfferingDetailsI
) => (
  <div className="offering">
    <Row>
      <Col>
        <h1 className="heading">Offering details</h1>
      </Col>
    </Row>

    <div className="offering__body">
      <ShowIfTrue if={investmentProductDescription}>
        <Row className="d-md-none offering__row">
          <Col className="offering__col-left" xs={12} md={6}>Instrument description</Col>
          <Col className="offering__col-right" xs={12} md={6}>{investmentProductDescription}</Col>
        </Row>
      </ShowIfTrue>

      <ShowIfTrue if={priceInUSD}>
        <Row className="offering__row">
          <Col className="offering__col-left" xs={6}>Initial Price Per Share</Col>
          <Col className="offering__col-right" xs={6}>${new BigNumber(priceInUSD).toFormat(2)}</Col>
        </Row>

        <RowDivider className="d-none d-md-block"/>
      </ShowIfTrue>

      <ShowIfTrue if={startDate}>
        <Row className="offering__row">
          <Col className="offering__col-left" xs={6}>Offering Start Date</Col>
          <Col className="offering__col-right" xs={6}>{formatDate(startDate)}</Col>
        </Row>
      </ShowIfTrue>

      <ShowIfTrue if={endDate}>
        <RowDivider className="d-none d-md-block"/>

        <Row className="offering__row">
          <Col className="offering__col-left" xs={6}>Offering End Date</Col>
          <Col className="offering__col-right" xs={6}>{formatDate(endDate)}</Col>
        </Row>
      </ShowIfTrue>

      <ShowIfTrue if={lockupEndDate}>
        <RowDivider className="d-none d-md-block"/>

        <Row className="offering__row">
          <Col className="offering__col-left" xs={6}>Lockup End Date</Col>
          <Col className="offering__col-right" xs={6}>{formatDate(lockupEndDate)}</Col>
        </Row>
      </ShowIfTrue>

      <ShowIfTrue if={investmentProductDescription}>
        <RowDivider className="d-none d-md-block"/>

        <Row className="d-none d-md-flex offering__row">
          <Col className="offering__col-left" xs={6}>Instrument description</Col>
          <Col className="offering__col-right" xs={6}>{investmentProductDescription}</Col>
        </Row>
      </ShowIfTrue>

      <ShowIfTrue if={totalSharesAvailable}>
        <RowDivider className="d-none d-md-block"/>

        <Row className="offering__row">
          <Col className="offering__col-left" xs={6}>Total Authorized Shares</Col>
          <Col className="offering__col-right" xs={6}>{new BigNumber(totalSharesAvailable).toFormat(0)}</Col>
        </Row>
      </ShowIfTrue>

      <ShowIfTrue if={minInvestmentInUSD}>
        <RowDivider className="d-none d-md-block"/>

        <Row className="offering__row">
          <Col className="offering__col-left" xs={6}>Minimum Investment</Col>
          <Col className="offering__col-right" xs={6}>${new BigNumber(minInvestmentInUSD).toFormat(2)}</Col>
        </Row>
      </ShowIfTrue>

      <div className="big-padding"/>

      <Row className="offering__row">
        <Col className="offering__col-left" xs={12} lg={6}>
          {projectAsset.providerId === "celo" ? "Celo contract address" : "Ethereum contract address"}
        </Col>
        <Col className="offering__col-right offering__address" xs={12} lg={6}>
          <a href={projectAsset.providerId === "celo" ? `https://explorer.celo.org/address/${projectAsset.address}` : `https://etherscan.io/address/${projectAsset.address}`}
             target="_blank"
             rel="noreferrer"
          >
            {projectAsset.address}
          </a>
        </Col>
      </Row>

      <div className="small-padding"/>

      <Row className="offering__row">
        <Col className="offering__col-left" xs={12} lg={6}>Uniswap Token Page</Col>
        <Col className="offering__col-right offering__address" xs={12} lg={6}>
          {
            projectPair && projectPair.address
              ? <a href={`https://info.uniswap.org/pair/${projectPair?.address}`}
                   target="_blank"
                   rel="noreferrer"
              >
                {projectPair?.address}
              </a>
              : "N/A"
          }
        </Col>
      </Row>
    </div>
  </div>
);

export default OfferingDetails;
