interface IconData {
  id: string;
  icon: string;
  url: string;
}

export const iconsData: IconData[] = [
  {
    id: "15e1ad28-8020-4c7d-b477-b664e26c0186",
    icon: "linkedin_icon.svg",
    url: "https://www.linkedin.com/company/unicorn-tokenization-corp"
  },
  {
    id: "2dc1d58d-cb1b-49f7-acb6-88ab05d4b04e",
    icon: "fb_icon.svg",
    url: "https://www.facebook.com/UnicornTokenizationCorp"
  },
  {
    id: "1bdd51ec-93fe-4d83-9d01-cb61ac5b6436",
    icon: "medium_icon.svg",
    url: "https://medium.com/@ambisafe/ambisafes-orderbook-is-democratizing-financial-markets-by-issuing-on-chain-fractional-shares-of-68266c16f10c"
  },
  {
    id: "6e6a0df5-307e-440e-b0a3-c512b3cd07dc",
    icon: "telegram_icon.svg",
    url: "https://t.me/unicorn_investors_club"
  },
  {
    id: "86e4f365-9dd5-4786-a935-45f1e48e329b",
    icon: "twitter_icon.svg",
    url: "https://twitter.com/utc_fund"
  }
];
