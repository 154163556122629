import React from "react";
import Col from "react-bootstrap/Col";
import { ASSETS_URL } from "../../../../utils";
import Row from "react-bootstrap/Row";
import { TeamMemberI } from "../types";
import "./index.css";
import MediaIcon, { MediaIconI } from "../../../MediaIcon";

const MediaIcons = ({ data }: { data: MediaIconI[] }) => <div style={{margin: "0 -10px"}}>
  {data.map(({ icon, url }, index) => <MediaIcon key={index} icon={icon} url={url} newTab={true}/>)}
</div>;

const TeamMember = ({ photo, name, position, description, mediaLinks }: TeamMemberI) => (
  <Row className="team-member">
    <Col xs={"auto"} md={3}>
      <Row>
        <Col xs={12}>
          <img className="team-member__photo" src={`${ASSETS_URL}/${photo}`} alt="Team member"/>
        </Col>

        <Col xs={12} className="d-none d-md-flex justify-content-center d-lg-none" style={{paddingTop: "29px"}}>
          <MediaIcons data={mediaLinks}/>
        </Col>
      </Row>
    </Col>

    <Col>
      <Row>
        <Col xs={12} className="d-flex justify-content-between">
          <span className="team-member__name">{name}</span>

          <span className="d-none d-lg-inline">
            <MediaIcons data={mediaLinks}/>
          </span>
        </Col>

        <Col xs={12} className="team-member__position">{position}</Col>

        <Col xs={12} className="d-none d-md-flex team-member__description">{description}</Col>
      </Row>
    </Col>

    <Col xs={12} className="d-md-none team-member__description">{description}</Col>

    <Col className="d-md-none">
      <MediaIcons data={mediaLinks}/>
    </Col>
  </Row>
);

export default TeamMember;