import React from "react";
import { ASSETS_URL } from "../../utils";

import "./index.css";

interface CardI {
  icon: string;
  heading: string;
  text: string;
}

const Card = ({icon, heading, text}: CardI) => (
  <div className="item">
    <div className="item__container">
      <img style={{paddingRight: "21px"}} src={`${ASSETS_URL}/${icon}`} alt="Benefit icon"/>
      <div className="item__heading-container"><h3 className="item__heading">{heading}</h3></div>
    </div>
    <p className="item__description" dangerouslySetInnerHTML={{__html: text}} />
  </div>
);

export default Card;