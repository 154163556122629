import { useEffect, useState } from "react";
import { getBreakpoint } from "./utils";

const useBreakpoints = (breakpoints: string[]) => {
  const hasMatches = breakpoints.includes(getBreakpoint(window.innerWidth));
  const [isBreakpointsActive, setActive] = useState(hasMatches);

  useEffect(() => {
    window.addEventListener("resize", ev => {
      const window = ev.target as Window;

      setActive(breakpoints.includes(getBreakpoint(window.innerWidth)));
    })
  });

  return isBreakpointsActive;
};

export default useBreakpoints;
