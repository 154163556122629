import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
  query Projects($pageSize: Int!, $page: Int!) {
    projects(geoRestriction: false, pageSize: $pageSize, page: $page) {
      data {
        id
        title
        description
        status
        shortDescription
        link
        country
        currentCountry
        restrictedCountries
        logoImage
        coverImage
        startDate
        endDate
        softCap
        hardCap
        tokensTotal
        tokensSold
        priceInUSD
        category
        valuationInUSD
        dailyInvestmentInUSD
        volume24h
        projectAsset {
          assetId
          assetSymbol
          displaySymbol
          icon
          title
        }
        projectPair {
          address
          assetIdToken0
          assetIdToken1
          balance0
          balance1
          dirty
          id
          isActive
          traderFee
        }
      }
      meta {
        total
        page
        pageSize
      }
    }
  }
`;
