interface DocData {
  id: string;
  link: string;
  text: string;
}

export const docsData: DocData[] = [
  {
    id: "285353ff-5010-4192-a5c0-ee4c60d1b9f3",
    link: "https://help.ambisafe.com/article/92-overview-of-integration-options",
    text: "Overview of integration options"
  },
  {
    id: "ba92d2f5-d82e-41c6-9822-c78e00d4a5f6",
    link: "https://app.swaggerhub.com/apis/Aldekein/token-holder-reporting/2.0.0",
    text: "KYC Data Sharing API"
  },
  {
    id: "0b7be0f9-516c-4a40-a55e-404cf5bd794d",
    link: "https://help.ambisafe.com/article/91-rap-protocol-whitelisting",
    text: "RAP Address Whitelisting Tutorial"
  },
];
