export const benefitsData = [
  {
    icon: "invest_icon.svg",
    heading: "Invest in successful companies before their IPO",
    text: "Equity of successful companies is difficult to find, and the offerings are not available to everyone. Our fund structure allows a much broader audience to participate while staying in compliance with securities laws, enabling investors to invest in the pre-IPO company before it goes public."
  },
  {
    icon: "private_equity_icon.png",
    heading: "Better liquidity or private equity",
    text: "Private equity is known for lack of liquidity. Depending on the size of the company, it usually takes weeks to months to sell. We decrease minimal transaction time from weeks to minutes by making it digital and listing it on cryptocurrency exchanges."
  },
  {
    icon: "smaller_minimums_icon.svg",
    heading: "Smaller minimums",
    text: "A typical investment minimum for a Pre-IPO company is ranging between <b>$25,000</b> and <b>$200,000</b>. We decrease it to <b>$100</b> using the advances of blockchain technology, thus enabling broader financial inclusion and better democratization of investment opportunities. "
  },
];
