import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import TeamAndPartners from "./TeamAndPartners";
import { getLinks } from "./utils";
import { linksData } from "./data";

const About = () => {
  const links = getLinks(linksData);

  return (
    <div className="about">
      <Row>
        <Col>
          <h1 className="heading">About</h1>
        </Col>
      </Row>

      <Row className="about_description">
        <Col>
          <p className="about__text" style={{ marginBottom: "2rem" }}>
            {/*@ts-ignore*/}
            <b>Unicorn Tokenization Corp.</b> is a subsidiary of {links.ambisafe},
            registered at BVI as a Private Investment Fund
            (Company number #2011776, FSC Certificate No.&nbsp;SIBA/PIF/20/0184).</p>
          {/*@ts-ignore*/}
          <p className="about__text" style={{ marginBottom: "2rem" }}>The Fund is managed by {links.raison}&nbsp;
            (legal name “Threesixty Elements S.A.”) which is authorized and licensed by the BVI Financial Services
            Commission (“FSC”) under the Securities and Investment Business Act,
            2010 (“SIBA”) and Investment Business (Approved Managers) Regulations, 2012 in the British Virgin Islands
            (“BVI”).</p>
          <p className="about__text">The fund is using a combination of innovative legal&nbsp;
            {/*@ts-ignore*/}
            structure, {links.RAP}, developed by Ambisafe, and public {links.ethereum} and {links.celo} blockchains,&nbsp;
            {/*@ts-ignore*/}
            to facilitate its issuances and platform listings. Please contact us at {links.ambisafeEmail} if you’re
            interested to learn more about technology
            and legal structure.</p>
        </Col>
      </Row>

      <TeamAndPartners/>
    </div>
  )
};

export default About;