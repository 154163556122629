import React, { forwardRef } from "react";
import { LoadSpinner } from "./loadSpinner";
import "./index.css";

interface ButtonProps {
  ref?: React.Ref<HTMLButtonElement>;
  onClick?: () => void;
  loading?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ hidden, onClick, loading, children }, ref) => {
    return (
      <button 
        ref={ref}
        hidden={hidden}
        onClick={onClick}
        className="button-main"
      >
        {loading ? <LoadSpinner /> : children}
      </button>
    );
  }
);
