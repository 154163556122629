import React from "react";

export const LoadSpinner = () => (
  <div
    className="d-inline-flex container-fluid justify-content-center align-items-center"
    style={{ height: "100%" }}
  >
    <svg
      className="rotating"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0286 11C21.5651 11 22.0045 10.5642 21.9571 10.0298C21.7688 7.9022 20.964 5.86762 19.6312 4.18068C18.0969 2.23877 15.9526 0.870997 13.5449 0.298431C11.1372 -0.274136 8.6067 -0.0180346 6.36253 1.02534C4.11835 2.06871 2.2916 3.83839 1.17753 6.04833C0.0634639 8.25826 -0.27281 10.7793 0.223061 13.204C0.718932 15.6287 2.01797 17.8153 3.91021 19.4104C5.80245 21.0055 8.17731 21.9159 10.6509 21.9945C12.7998 22.0627 14.9141 21.4997 16.7366 20.3857C17.1943 20.106 17.2833 19.4936 16.9643 19.0623C16.6452 18.631 16.0391 18.5451 15.5762 18.8161C14.1095 19.6749 12.4243 20.107 10.7126 20.0527C8.67584 19.988 6.72042 19.2384 5.16238 17.925C3.60435 16.6116 2.53474 14.8112 2.12645 12.8147C1.71815 10.8183 1.99504 8.7425 2.91234 6.92287C3.82964 5.10325 5.33376 3.64613 7.18158 2.78703C9.0294 1.92793 11.1129 1.71706 13.0954 2.18851C15.0779 2.65995 16.8435 3.78615 18.1068 5.38508C19.1685 6.7289 19.8233 8.34062 20.0052 10.0304C20.0626 10.5638 20.4921 11 21.0286 11Z"
        fill="#B8D9FF"
      />
    </svg>
  </div>
);
