export const linksData = [
  { id: "ambisafe", to: "https://ambisafe.com/", label: "Ambisafe Group" },
  { id: "raison", to: "https://raison.am/", label: "Raison Asset Management" },
  {
    id: "RAP",
    to: "https://ambisafe.com/regulation_aware_protocol.html",
    label: "Regulatory Aware Protocol"
  },
  { id: "ethereum", to: "https://ethereum.org/en/", label: "Ethereum" },
  { id: "celo", to: "https://celo.org/", label: "Celo" },
  { id: "ambisafeEmail", to: "mailto:sales@ambisafe.com", label: "sales@ambisafe.com" },
];
