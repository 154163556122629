import React, {
  FC,
  useEffect,
  useRef,
  useState
} from "react";
import {
  Row,
  Table,
  Container
} from "react-bootstrap";

import "./index.css";
import { Button } from "./Button";
import { CompaniesComponentProps } from "./types";
import { TableRowContainer } from "./TableRowContainer";
import { useAutoClickOnRef } from "./useAutoClickOnRef";
import { getLoadMoreVisibility } from "../utils";

export const Companies: FC<CompaniesComponentProps> = ({
  error,
  loading,
  hasMore,
  projects,
  handleFetchMore,
}) => {
  const page = useRef(1);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  const buttonRef = useAutoClickOnRef({
    shouldClickNext: shouldLoadMore,
    shouldDisconnectRef: !hasMore,
  });

  const handleLoadMoreClick = async () => {
    if (!shouldLoadMore) {
      setShouldLoadMore(true);
    }

    if (hasMore && !loading) {
      const nextPage = page.current + 1;

      await handleFetchMore(nextPage);
      page.current += 1;
    }
  };

  useEffect(() => {
    window.onunload = function () {
      sessionStorage.removeItem('projectsLoaded');
    }
  }, [])

  if (error) {
    return <div>"Some error occurred"</div>;
  }

  return (
    <>
      <h1 className="companies">Tokenized Companies</h1>

      <Table striped responsive className="companies-table">
        <thead>
          <tr>
            <th>Company</th>
            <th>Symbol</th>
            <th>Last price</th>
            {/* <th>24h</th> */}
            <th className="d-none d-md-table-cell">Latest valuation</th>
            <th className="d-none d-lg-table-cell">Token Market Cap</th>
            <th>Volume</th>
            <th className="d-none d-md-table-cell">{}</th>
          </tr>
        </thead>

        <tbody>
          {projects.map((project, index) => (
            <TableRowContainer key={index} project={project} />
          ))}
        </tbody>
      </Table>

      <Container className="width-100 mb-5">
        <Row className="justify-content-center h6">
          <Button
            hidden={!hasMore || getLoadMoreVisibility()}
            ref={buttonRef}
            onClick={handleLoadMoreClick}
            loading={loading}
          >
            Load more
          </Button>
        </Row>
      </Container>
    </>
  );
};
