import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Partner from "./Partner";
import { partners, team } from "./data";
import "./index.css";
import TeamMember from "./TeamMember";

const TeamAndPartners = () => (
  <div className="team-and-partners">
    <Row>
      <Col>
        <h1 className="heading">Team & Partners</h1>
      </Col>
    </Row>

    <Row className="partners">
      {partners.map((props, index) => <Partner key={index} {...props}/>)}
    </Row>

    <div className="team-members">
      {team.map(({ photo, name, position, description, mediaLinks }, index) => (
        <TeamMember
          key={index}
          photo={photo}
          name={name}
          position={position}
          description={description}
          mediaLinks={mediaLinks}
        />
      ))}
    </div>
  </div>
);

export default TeamAndPartners;