import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import "./index.css";

interface LinkI {
  id?: string;
  to: string;
  external?: boolean;
  newTab?: boolean;
  className?: string;
  children: any;
}

const Link = ({ id, to, external = false, newTab = false, className, children }: LinkI) => {
  const linkProps = {
    className: `router-link ${className}`,
    target: newTab ? "_blank" : "_self",
    ...(newTab && { rel: "noreferrer" })
  };

  return external
    ? <a id={id} href={to} {...linkProps}>{children}</a>
    : <RouterLink id={id} to={to} {...linkProps} >{children}</RouterLink>;
};

export default Link;
