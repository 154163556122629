import { JSONResponse, PlatformData } from "./types";
import { platformsMap } from "./data";

const getPlatformData = (platformName: string, projectLink: string): PlatformData => {
  const { url, ...rest } = platformsMap[platformName];

  switch (platformName) {
    case "orderbook":
      return {
        ...rest,
        url: `${url}/${projectLink}`
      };
    case "raison":
      return {
        ...rest,
        url: `${url}/${projectLink}`
      };
    default:
      return {
        ...rest,
        url
      }
  }
};

export const getPlatformsData = (jsonData: JSONResponse | undefined, projectLink: string): PlatformData[] => {
  if (jsonData && jsonData.data) {
    return jsonData.data.platforms.map(platform => {
      const platformName = platform.name.toLowerCase().split(".")[0];

      return getPlatformData(platformName, projectLink);
    });
  } else {
    return [];
  }
};
