export const formatDate = (isoDateString: string): string => {
  const d = new Date(isoDateString);
  const year = d.getFullYear();
  const date = d.getDate();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const monthName = months[d.getMonth()];

  return `${monthName} ${date}, ${year}`;
};
