import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ASSETS_URL } from "../../../../utils";
import "./index.css";
import Link from "../../../Link";

interface PartnerI {
  url: string;
  logo: string;
  description: string;
}

const Partner = ({ url, logo, description }: PartnerI) => (
  <Col xs={12} lg={4} className="partner">
    <Row>
      <Col xs={12} md={3} lg={12}>
        <Link to={url} external={true}>
          <img className="partner__logo" src={`${ASSETS_URL}/${logo}`} alt="ambisafe logo"/>
        </Link>
      </Col>

      <Col xs={12} md={9} lg={12} className="about__text">
        {description}
      </Col>
    </Row>
  </Col>
);

export default Partner;
