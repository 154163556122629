import { useCallback } from "react";
import { AutoClickOptions } from "./types";

export const useAutoClickOnRef = ({
  shouldClickNext,
  shouldDisconnectRef,
}: AutoClickOptions) => {
  return useCallback(
    (node) => {
      if (!node) return;

      const intersectionObserverHandler: IntersectionObserverCallback = (
        entries,
        observer
      ) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            shouldClickNext && node?.click();
            shouldDisconnectRef && observer.disconnect();
          }
        });
      };

      const options = {
        rootMargin: "50px",
        threshold: 1,
      };

      const observer = new IntersectionObserver(
        intersectionObserverHandler,
        options
      );

      observer.observe(node);

      return () => {
        observer.disconnect();
      };
    },
    [shouldClickNext, shouldDisconnectRef]
  );
};
