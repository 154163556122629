import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import { Project } from "../types";
import Accordion from "react-bootstrap/Accordion";
import BigNumber from "bignumber.js";
import CustomToggle from "./CustomToggle";
import RowDivider from "./RowDivider";
import { ShowIfTrue } from "../utils";

interface CompanyDetailsI {
  project: Project;
}

const CompanyDetails = (
  {
    project: {
      companyName,
      externalLink,
      category,
      valuationInUSD,
      foundedYear,
      headquarters,
      employees,
      latestFundingRound,
      description
    }
  }: CompanyDetailsI
) => {
  const companyDescription = description.replace(/<[^>]+>/g, '');
  const latestValuation = new BigNumber(valuationInUSD).div(1000000000).toFormat(0);

  return (
    <div className="company">
      <Row>
        <Col>
          <h1 className="heading">Company details</h1>
        </Col>
      </Row>

      <div className="company__info">
        <ShowIfTrue if={companyName}>
          <Row className="info__row">
            <Col className="info__col-left" xs={12} md={6}>Company name</Col>
            <Col className="info__col-right" xs={12} md={6}>
              <div>{companyName}</div>
            </Col>
          </Row>

          <RowDivider className="d-none d-md-block"/>
        </ShowIfTrue>

        <ShowIfTrue if={externalLink}>
          <div className="d-block d-md-none" style={{ marginBottom: "16px" }}>
            <a className="info__website" href={externalLink}>{externalLink}</a>
          </div>
        </ShowIfTrue>

        <ShowIfTrue if={category}>
          <Row className="info__row">
            <Col className="info__col-left" xs={6}>Category</Col>
            <Col className="info__col-right" xs={6}>{category}</Col>
          </Row>
        </ShowIfTrue>

        <ShowIfTrue if={latestValuation}>
          <RowDivider className="d-none d-md-block" />

          <Row className="info__row">
            <Col className="info__col-left" xs={6}>Latest valuation</Col>
            <Col className="info__col-right" xs={6}>${latestValuation}B</Col>
          </Row>
        </ShowIfTrue>

        <ShowIfTrue if={externalLink}>
          <RowDivider className="d-none d-md-block"/>

          <Row className="d-none d-md-flex info__row">
            <Col className="info__col-left" md={6}>Company website</Col>
            <Col className="info__col-right" md={6}>
              <a className="info__website" href={externalLink}>{externalLink}</a>
            </Col>
          </Row>
        </ShowIfTrue>

        <ShowIfTrue if={foundedYear}>
          <RowDivider className="d-none d-md-block"/>

          <Row className="info__row">
            <Col className="info__col-left" xs={6}>Founded (year)</Col>
            <Col className="info__col-right" xs={6}>{foundedYear}</Col>
          </Row>
        </ShowIfTrue>

        <ShowIfTrue if={headquarters}>
          <RowDivider className="d-none d-md-block"/>

          <Row className="info__row">
            <Col className="info__col-left" xs={6}>Headquarters</Col>
            <Col className="info__col-right" xs={6}>{headquarters}</Col>
          </Row>
        </ShowIfTrue>

        <ShowIfTrue if={employees}>
          <RowDivider className="d-none d-md-block"/>

          <Row className="info__row">
            <Col className="info__col-left" xs={6}>Employees</Col>
            <Col className="info__col-right" xs={6}>{new BigNumber(employees).toFormat()}</Col>
          </Row>
        </ShowIfTrue>

        <ShowIfTrue if={latestFundingRound}>
          <RowDivider className="d-none d-md-block"/>

          <Row className="info__row">
            <Col className="info__col-left" xs={6}>Latest funding round</Col>
            <Col className="info__col-right" xs={6}>{latestFundingRound}</Col>
          </Row>
        </ShowIfTrue>
      </div>

      <Row>
        <Col>
          <div className="company__description">
            <Accordion className="d-xs-block d-md-none">
              <CustomToggle eventKey="0" />
              <Accordion.Collapse eventKey="0">
                <div className="text-justify" style={{ marginTop: "25px" }}>{companyDescription}</div>
              </Accordion.Collapse>
            </Accordion>
            <div className="d-none d-md-block text-justify">{companyDescription}</div>
          </div>
        </Col>
      </Row>
    </div>
  )
};

export default CompanyDetails;
