import React, { useContext } from "react";
import { AccordionContext, useAccordionToggle } from "react-bootstrap";
import { ASSETS_URL } from "../../../utils";

const CustomToggle = ({ eventKey }: { eventKey: string }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div className="description__switcher" onClick={decoratedOnClick}>
      <span>{isCurrentEventKey ? "Hide" : "Show"} info</span>
      <img
        className={isCurrentEventKey ? "description__switcher_down" : "description__switcher_up"}
        src={`${ASSETS_URL}/arrow_right.svg`}
        alt="Show more"
      />
    </div>
  );
};

export default CustomToggle;
