import React from "react";
import { ASSETS_URL } from "../../../utils";
import { Description } from "./Description";

export const Info = () => <>
  <div className="home__container">
    <div>
      <h1 className="home__heading">Unicorn Tokenization Corp.</h1>
      <div className="d-none d-lg-block">
        <Description/>
      </div>
    </div>

    <img className="home__image" src={`${ASSETS_URL}/ETH_illustration_2.svg`} alt="Eth illustration"/>
  </div>

  <div className="d-lg-none home__description-mobile">
    <Description/>
  </div>
</>;
