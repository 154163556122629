import { Project } from "./Companies/types";

export const getModalVisibility = () => {
  const sessionVal = sessionStorage.getItem("isModalShown");
  return sessionVal === null;
};

export const isCountryRestricted = (projects: Project[]) => {
  return projects.length
    ? projects
        .map(({ restrictedCountries, currentCountry }) => {
          return Array.isArray(restrictedCountries)
            ? restrictedCountries.includes(currentCountry)
            : false;
        })
        .reduce((accum, current) => accum && current)
    : false;
};

export const getLoadMoreVisibility = () => {
  const sessionVal = sessionStorage.getItem("projectsLoaded");
  return sessionVal !== null;
};
