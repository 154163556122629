import { gql } from "@apollo/client";

export const PAIR_DAY_DATAS = gql`
  query pairDayDatas($pairAddress: String!) {
    pairDayDatas(where: {
      pairAddress: $pairAddress,
    },
      orderBy: date,
      orderDirection: desc,
      first: 2 
    ) {
      date
      token0 {
        id
        symbol
      }
      token1 {
        id
        symbol
      }
      reserve0
      reserve1
      dailyVolumeUSD
    }
  }
`;
