import React, { ReactElement } from "react";

interface ShowInTrueI {
  if: any;
  children: any
}

export const ShowIfTrue = ({ if: ifArg, children }: ShowInTrueI): ReactElement | null => {
  return !!ifArg ? <>{children}</> : null;
};
