import React from "react";
import Link from "../Link";

interface LinkI {
  id: string;
  to: string;
  label: string;
}

export const getLinks = (links: LinkI[]) => links.reduce((accumulator, { id, to, label }) => Object.assign(accumulator, {
  [id]: <Link className="about__link" to={to} external={true}>{label}</Link>
}), {});
