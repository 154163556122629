import { ProjectAsset, ProjectPair } from "../../types";
import { PairDayDatas } from "../types";
import BigNumber from "bignumber.js";

export const getDates = () => {
  const d = new Date();

  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const day = d.getUTCDate()

  const today = new Date(Date.UTC(year, month, day)).getTime();
  const yesterday = today - 86400000;

  return {
    yesterday: {
      inSeconds: yesterday / 1000,
      inMilliseconds: yesterday
    },
    today: {
      inSeconds: today / 1000,
      inMilliseconds: today
    }
  };
}

/*
Sell X amount of token A, receiving token B
 */
export const calculateSellPrice = (reserveA: string | number, reserveB: string | number, amount: number) => {
  const Ra = new BigNumber(reserveA);
  const Rb = new BigNumber(reserveB);
  const X = new BigNumber(amount);

  return X.times(0.997).times(Rb).div(Ra.plus(X.times(0.997)));
}

export const getLastPrice = (projectPair: ProjectPair | undefined, projectAssetId: string, priceInUSD: string): BigNumber => {
  let lastPrice: BigNumber;

  if (projectPair) {
    const { assetIdToken0, assetIdToken1, balance0, balance1 } = projectPair;

    const groupedPairDataByID = [
      {
        assetId: assetIdToken0,
        balance: balance0
      },
      {
        assetId: assetIdToken1,
        balance: balance1
      }
    ];

    const projectBalance = groupedPairDataByID.filter((pairData) => parseInt(pairData.assetId) === parseInt(projectAssetId))[0];
    const counterBalance = groupedPairDataByID.filter((pairData) => parseInt(pairData.assetId) !== parseInt(projectAssetId))[0];

    lastPrice = calculateSellPrice(projectBalance.balance, counterBalance.balance, 1);
  } else {
    lastPrice = new BigNumber(priceInUSD);
  }

  return lastPrice;
}

export const getTokenPrice = (pair: PairDayDatas, projectAssetSymbol: string) => {
  const { token0, token1, reserve0, reserve1 } = pair;

  const groupedPairDataBySymbol = [
    {
      displaySymbol: token0.symbol,
      balance: reserve0
    },
    {
      displaySymbol: token1.symbol,
      balance: reserve1
    }
  ];

  const projectBalance = groupedPairDataBySymbol.find(({ displaySymbol }) => displaySymbol === projectAssetSymbol);
  const counterBalance = groupedPairDataBySymbol.find(({ displaySymbol }) => displaySymbol !== projectAssetSymbol);

  // @ts-ignore
  return calculateSellPrice(projectBalance.balance, counterBalance.balance, 1).toNumber();
}

export const getDailyChanges = (pairDayDatas: PairDayDatas[], projectAssetDisplaySymbol: string): {
  positive?: boolean,
  value: string
} => {
  const todayPair = pairDayDatas[0];
  const yesterdayPair = pairDayDatas[1];

  const todayPairPrice = todayPair ? getTokenPrice(todayPair, projectAssetDisplaySymbol) : 0;
  const yesterdayPairPrice = yesterdayPair ? getTokenPrice(yesterdayPair, projectAssetDisplaySymbol) : 0;

  if (todayPairPrice && yesterdayPairPrice && todayPairPrice !== yesterdayPairPrice) {
    const delta = (todayPairPrice - yesterdayPairPrice) * 100 / yesterdayPairPrice;

    return {
      positive: todayPairPrice > yesterdayPairPrice,
      value: new BigNumber(delta).abs().toFormat(1, BigNumber.ROUND_UP)
    };
  } else {
    return {
      value: "0.0"
    };
  }
}

export const getTodayVolume = (pairDayDatas: PairDayDatas[]) => {
  const { today } = getDates();

  const todayPair = pairDayDatas.find(({ date }) => date === today.inSeconds);

  return todayPair ? `$${new BigNumber(todayPair.dailyVolumeUSD).toFormat(0)}` : "N/A";
}

export const getLatestValuation = (valuationInUSD: string): string => {
  const valuationValue = new BigNumber(valuationInUSD).div(1000000000);

  return valuationValue.isGreaterThan(1)
    ? valuationValue.toFormat(0)
    : valuationValue.toFormat(1);
};

export const getTokenMarketCap = (tokensTotal: string, lastPrice: BigNumber): string => {
  return new BigNumber(tokensTotal).times(lastPrice).toFormat(0);
}

export const getVolume = (pairDayDatas: PairDayDatas[], dailyInvestmentInUSD: string): string => {
  return pairDayDatas.length
    ? getTodayVolume(pairDayDatas)
    : dailyInvestmentInUSD
      ? `$${new BigNumber(dailyInvestmentInUSD).toFormat(0)}`
      : "N/A";
};

export const getCompanyName = (title: string): string => {
  const matches = title.match(/^(?:fractional|tokenized)\s+shares\s+of\s+(.+)$/i);

  return Array.isArray(matches) && matches[1] ? matches[1] : title;
}

export const getProjectLink = (projectAsset: ProjectAsset): string => {
  const symbol = projectAsset.displaySymbol
    ? projectAsset.displaySymbol
    : projectAsset.assetSymbol;

  return `/${symbol.toUpperCase()}`;
};
