import React from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "./queries";
import { ProjectArgs, ProjectResponse } from "./types";
import Title from "./Title";
import Platforms from "./Platforms";
import CompanyDetails from "./CompanyDetails";
import OfferingDetails from "./OfferingDetails";

import "./index.css";

const Project = ({ assetSymbol }: { assetSymbol: string }) => {
  const { data, loading, error } = useQuery<ProjectResponse, ProjectArgs>(GET_PROJECT, {
    variables: {
      assetSymbol
    }
  });

  if (loading) {
    return <>Loading...</>
  }

  if (error) {
    return <>Some error occurred</>
  }

  if (!data || data?.projectByAssetSymbol === null) {
    return <Redirect to={"/"}/>;
  }

  return (
    <>
      <Title project={data.projectByAssetSymbol}/>

      <Platforms
        projectAssetAddress={data.projectByAssetSymbol.projectAsset.address}
        projectLink={data.projectByAssetSymbol.link}
        providerId={data.projectByAssetSymbol.projectAsset.providerId}
      />

      <CompanyDetails project={data.projectByAssetSymbol}/>

      <OfferingDetails project={data.projectByAssetSymbol}/>
    </>
  )
}

export default Project;
