import React from "react";
import Row from "react-bootstrap/Row";
import { benefitsData } from "./benefitsData";
import Col from "react-bootstrap/Col";
import Card from "../../Card";

export const Benefits = () => <>
  <h1 className="home__benefits">Benefits</h1>

  <Row className="home__items">
    {benefitsData.map((props, index) => <Col key={index}><Card {...props} /></Col>)}
  </Row>
</>;