import React from "react";
import "./index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MediaIcon from "../MediaIcon";
import { iconsData } from "./iconsData";
import { ASSETS_URL } from "../../utils";

const Footer = () => (
  <Container fluid>
    <Row className="footer__divider-container">
      <Col>
        <div className="divider" />
      </Col>
    </Row>

    <Row className="footer__icons-container">
      <Col xs="auto">
        {iconsData.map((icon, index) => <MediaIcon key={index} {...icon} newTab />)}
      </Col>
    </Row>

    <Row className="footer__title-container">
      <Col xs="auto">
        <h4 className="footer__title">Unicorn Tokenization Corp.<br />{new Date().getFullYear()}</h4>
      </Col>
    </Row>

    <Row className="footer__warning-icon-container">
      <Col xs="auto">
        <img src={`${ASSETS_URL}/warning.svg`} alt="Warning" />
      </Col>
    </Row>

    <Row className="footer__description-container">
      <Col xs="auto">
        <p className="footer__description">Any information about Companies is gathered from publicly available sources
          and is neither verified by the sponsors of the tokenized instruments nor endorsed by Companies. Fractional
          share holders are not shareholders of the Tokenized Companies and do not get any voting or information rights
          in these companies. Offerings are not available for the residents of United States, Japan, Singapore, and
          Switzerland.</p>
      </Col>
    </Row>
  </Container>
);

export default Footer;
