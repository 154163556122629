import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import { ASSETS_URL } from "../../utils";

import "./index.css";

const linksData = [
  { to: "/", title: "Home" },
  { to: "/about", title: "About" },
  { to: "/for-platforms", title: "For platforms" }
];

export const Header = () => {
  const [isOpened, setOpenedState] = useState(false);

  return (
    <div style={{ backgroundColor: "#08080B" }}>
      <Container style={{ maxWidth: "1300px" }}>
        <Row noGutters={true}>
          <Col>
            <Navbar className="header__navbar" expand="md">
              {isOpened
                ? <div style={{ height: "50px" }}>{''}</div>
                : <Navbar.Brand href="/" style={{ display: "flex" }}>
                  <img
                    id="a5aaace5-c080-4835-98e6-8e33e32b856d"
                    className="logo-small"
                    src={`${ASSETS_URL}/logo.png`}
                    alt="logo"
                  />
                  <div id="fdc7a0fc-7c34-43ad-aced-94cd1a7a8d1c" className="header-logo__text header__text-small">
                    <span>Unicorn Tokenization</span><br/>
                    <span>Corp.</span>
                  </div>
                </Navbar.Brand>
              }

              <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setOpenedState(!isOpened)}>
                {isOpened
                  ? <img src={`${ASSETS_URL}/close_navbar.png`} alt="burger"/>
                  : <img src={`${ASSETS_URL}/menu_burger_icon.png`} alt="burger"/>
                }
              </Navbar.Toggle>

              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto mr-3">
                  {isOpened && <div className="header-logo-container">
                    <img className="logo-big"
                         src={`${ASSETS_URL}/logo_big.png`}
                         alt="Big logo"
                    />
                    <div className="header-logo__text header__text-big">
                      <span>Unicorn</span><br/>
                      <span>Tokenization</span><br/>
                      <span>Corp.</span>
                    </div>
                  </div>}

                  {linksData.map(({ to, title }, index) => (
                    <LinkContainer key={index} to={to} exact={true} activeStyle={{ fontWeight: 900 }}>
                      <Nav.Link className="header__link" href="/">{title}</Nav.Link>
                    </LinkContainer>
                  ))}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
